import {
  SET_POST,
  POST_DELETE_MODAL_OPEN,
  POST_DELETE_MODAL_CLOSE,
  POST_CHANGES_STATUS,
  SET_USER,
  USER_DELETE_MODAL_OPEN,
  USER_DELETE_MODAL_CLOSE,
  USER_CHANGES_STATUS,
  SET_FORM,
  FORM_DELETE_MODAL_OPEN,
  FORM_DELETE_MODAL_CLOSE,
  FORM_CHANGES_STATUS,
  SET_OFFER,
  OFFER_DELETE_MODAL_OPEN,
  OFFER_DELETE_MODAL_CLOSE,
  OFFER_CHANGES_STATUS,
  SET_REQUEST,
  REQUEST_DELETE_MODAL_OPEN,
  REQUEST_DELETE_MODAL_CLOSE,
  REQUEST_CHANGES_STATUS,
  SET_AGENTS_NOTIFICATION_DATA,
  AGENTS_NOTIFICATION_MODAL_OPEN,
  AGENTS_NOTIFICATION_MODAL_CLOSE,
  SET_FILTER,
  FILTER_MODAL_OPEN,
  FILTER_MODAL_CLOSE,
  FILTER_CHANGES_STATE,
  SET_FILTER_COMMENT,
  FILTER_COMMENT_MODAL_OPEN,
  FILTER_COMMENT_MODAL_CLOSE,
  SET_FILTER_CHNAGE_BASE_STATUS,
  FILTER_CHNAGE_BASE_STATUS_MODAL_OPEN,
  FILTER_CHNAGE_BASE_STATUS_MODAL_CLOSE,
  HOLDER_MODAL_OPEN,
  HOLDER_MODAL_CLOSE,
  SET_FILTER_PROP,
  FILTER_MODAL_OPEN_PROP,
  FILTER_MODAL_CLOSE_PROP,
  FILTER_CHANGES_STATE_PROP,
  SET_FILTER_COMMENT_PROP,
  FILTER_COMMENT_MODAL_OPEN_PROP,
  FILTER_COMMENT_MODAL_CLOSE_PROP,
  SET_FILTER_CHNAGE_BASE_STATUS_PROP,
  FILTER_CHNAGE_BASE_STATUS_MODAL_OPEN_PROP,
  FILTER_CHNAGE_BASE_STATUS_MODAL_CLOSE_PROP,
  HOLDER_PROP_MODAL_OPEN,
  HOLDER_PROP_MODAL_CLOSE,
  SET_FILTER_INVENTORY,
  FILTER_MODAL_OPEN_INVENTORY,
  FILTER_MODAL_CLOSE_INVENTORY,
  FILTER_CHANGES_STATE_INVENTORY,
  SET_FILTER_COMMENT_INVENTORY,
  FILTER_COMMENT_MODAL_OPEN_INVENTORY,
  FILTER_COMMENT_MODAL_CLOSE_INVENTORY,
  SET_FILTER_CHNAGE_BASE_STATUS_INVENTORY,
  FILTER_CHNAGE_BASE_STATUS_MODAL_OPEN_INVENTORY,
  FILTER_CHNAGE_BASE_STATUS_MODAL_CLOSE_INVENTORY,
  HOLDER_INVENTORY_MODAL_OPEN,
  HOLDER_INVENTORY_MODAL_CLOSE
} from '../utils/actions'

const admin_reducer = (state, action) => {
  switch (action.type) {
    // post
    case SET_POST:
      return { ...state, currentPost: action.payload }
    case POST_DELETE_MODAL_OPEN:
      return { ...state, isDeletePostModalOpen: true }
    case POST_DELETE_MODAL_CLOSE:
      return { ...state, isDeletePostModalOpen: false }
    case POST_CHANGES_STATUS:
      return { ...state, postChangesStatus: action.payload }
    // user
    case SET_USER:
      return { ...state, currentUser: action.payload }
    case USER_DELETE_MODAL_OPEN:
      return { ...state, isDeleteUserModalOpen: true }
    case USER_DELETE_MODAL_CLOSE:
      return { ...state, isDeleteUserModalOpen: false }
    case USER_CHANGES_STATUS:
      return { ...state, userChangesStatus: action.payload }
    // form
    case SET_FORM:
      return { ...state, currentForm: action.payload }
    case FORM_DELETE_MODAL_OPEN:
      return { ...state, isDeleteFormModalOpen: true }
    case FORM_DELETE_MODAL_CLOSE:
      return { ...state, isDeleteFormModalOpen: false }
    case FORM_CHANGES_STATUS:
      return { ...state, formChangesStatus: action.payload }
    // offer
    case SET_OFFER:
      return { ...state, currentOffer: action.payload }
    case OFFER_DELETE_MODAL_OPEN:
      return { ...state, isDeleteOfferModalOpen: true }
    case OFFER_DELETE_MODAL_CLOSE:
      return { ...state, isDeleteOfferModalOpen: false }
    case OFFER_CHANGES_STATUS:
      return { ...state, offerChangesStatus: action.payload }
    // request
    case SET_REQUEST:
      return { ...state, currentRequest: action.payload }
    case REQUEST_DELETE_MODAL_OPEN:
      return { ...state, isDeleteRequestModalOpen: true }
    case REQUEST_DELETE_MODAL_CLOSE:
      return { ...state, isDeleteRequestModalOpen: false }
    case REQUEST_CHANGES_STATUS:
      return { ...state, requestChangesStatus: action.payload }
    // Agents Notification
    case SET_AGENTS_NOTIFICATION_DATA:
      return { ...state, agentsNotificationData: action.payload }
    case AGENTS_NOTIFICATION_MODAL_OPEN:
      return { ...state, isAgentsNotificationModalOpen: true }
    case AGENTS_NOTIFICATION_MODAL_CLOSE:
      return { ...state, isAgentsNotificationModalOpen: false }
    // ============= request ==================
    // filter
    case SET_FILTER:
      return { ...state, currentFilter: action.payload }
    case FILTER_MODAL_OPEN:
      return { ...state, isFiltertModalOpen: true }
    case FILTER_MODAL_CLOSE:
      return { ...state, isFiltertModalOpen: false }
    case FILTER_CHANGES_STATE:
      return { ...state, filterChangesState: action.payload }
    // filter comment
    case SET_FILTER_COMMENT:
      return { ...state, currentFilterComment: action.payload }
    case FILTER_COMMENT_MODAL_OPEN:
      return { ...state, isFiltertCommentModalOpen: true }
    case FILTER_COMMENT_MODAL_CLOSE:
      return { ...state, isFiltertCommentModalOpen: false }
    // filter change base stats
    case SET_FILTER_CHNAGE_BASE_STATUS:
      return { ...state, currentFilterChangedBaseStatus: action.payload }
    case FILTER_CHNAGE_BASE_STATUS_MODAL_OPEN:
      return { ...state, isFiltertChangeBaseStatusModalOpen: true }
    case FILTER_CHNAGE_BASE_STATUS_MODAL_CLOSE:
      return { ...state, isFiltertChangeBaseStatusModalOpen: false }
    // holder
    case HOLDER_MODAL_OPEN:
      return { ...state, isHolderModalOpen: true }
    case HOLDER_MODAL_CLOSE:
      return { ...state, isHolderModalOpen: false }
    // ============= property ==================
    // filter
    case SET_FILTER_PROP:
      return { ...state, currentFilterProp: action.payload }
    case FILTER_MODAL_OPEN_PROP:
      return { ...state, isFiltertPropModalOpen: true }
    case FILTER_MODAL_CLOSE_PROP:
      return { ...state, isFiltertPropModalOpen: false }
    case FILTER_CHANGES_STATE_PROP:
      return { ...state, filterPropChangesState: action.payload }
    // filter comment
    case SET_FILTER_COMMENT_PROP:
      return { ...state, currentFilterPropComment: action.payload }
    case FILTER_COMMENT_MODAL_OPEN_PROP:
      return { ...state, isFiltertPropCommentModalOpen: true }
    case FILTER_COMMENT_MODAL_CLOSE_PROP:
      return { ...state, isFiltertPropCommentModalOpen: false }
    // filter change base stats
    case SET_FILTER_CHNAGE_BASE_STATUS_PROP:
      return { ...state, currentFilterPropChangedBaseStatus: action.payload }
    case FILTER_CHNAGE_BASE_STATUS_MODAL_OPEN_PROP:
      return { ...state, isFiltertPropChangeBaseStatusModalOpen: true }
    case FILTER_CHNAGE_BASE_STATUS_MODAL_CLOSE_PROP:
      return { ...state, isFiltertPropChangeBaseStatusModalOpen: false }
    // holder
    case HOLDER_PROP_MODAL_OPEN:
      return { ...state, isHolderPropModalOpen: true }
    case HOLDER_PROP_MODAL_CLOSE:
      return { ...state, isHolderPropModalOpen: false }
    
    // ============= inventory ==================
    // filter
    case SET_FILTER_INVENTORY:
      return { ...state, currentFilterInventory: action.payload }
    case FILTER_MODAL_OPEN_INVENTORY:
      return { ...state, isFiltertInventoryModalOpen: true }
    case FILTER_MODAL_CLOSE_INVENTORY:
      return { ...state, isFiltertInventoryModalOpen: false }
    case FILTER_CHANGES_STATE_INVENTORY:
      return { ...state, filterInventoryChangesState: action.payload }
    // filter comment
    case SET_FILTER_COMMENT_INVENTORY:
      return { ...state, currentFilterInventoryComment: action.payload }
    case FILTER_COMMENT_MODAL_OPEN_INVENTORY:
      return { ...state, isFiltertInventoryCommentModalOpen: true }
    case FILTER_COMMENT_MODAL_CLOSE_INVENTORY:
      return { ...state, isFiltertInventoryCommentModalOpen: false }
    // filter change base stats
    case SET_FILTER_CHNAGE_BASE_STATUS_INVENTORY:
      return { ...state, currentFilterInventoryChangedBaseStatus: action.payload }
    case FILTER_CHNAGE_BASE_STATUS_MODAL_OPEN_INVENTORY:
      return { ...state, isFiltertInventoryChangeBaseStatusModalOpen: true }
    case FILTER_CHNAGE_BASE_STATUS_MODAL_CLOSE_INVENTORY:
      return { ...state, isFiltertInventoryChangeBaseStatusModalOpen: false }
    // holder
    case HOLDER_INVENTORY_MODAL_OPEN:
      return { ...state, isHolderInventoryModalOpen: true }
    case HOLDER_INVENTORY_MODAL_CLOSE:
      return { ...state, isHolderInventoryModalOpen: false } 
    default:
      return state
  }
}

export default admin_reducer
